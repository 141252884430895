import {
  MASK_PATH,
  PREVIEW_ACCENT_COLORS,
  PREVIEW_NUMBER_COLORS,
  PREVIEW_NAME_COLORS,
  PREVIEW_MAIN_COLOR,
  DOTHTML
} from './config';

//b = background, v = version, p=plate
import b3v0 from 'url:/images/card/03-00.jpg';
import b3v1 from 'url:/images/card/03-01.jpg';
import b3v2 from 'url:/images/card/03-02.jpg';
import b3v3 from 'url:/images/card/03-03.jpg';
import p3 from 'url:/images/card/03.png';

import b4v0 from 'url:/images/card/04-00.jpg';
import b4v1 from 'url:/images/card/04-01.jpg';
import b4v2 from 'url:/images/card/04-02.jpg';
import b4v3 from 'url:/images/card/04-03.jpg';
import p4 from 'url:/images/card/04.png';

import b5v0 from 'url:/images/card/05-00.jpg';
import b5v1 from 'url:/images/card/05-01.jpg';
import b5v2 from 'url:/images/card/05-02.jpg';
import b5v3 from 'url:/images/card/05-03.jpg';
import p5 from 'url:/images/card/05.png';

import b6v0 from 'url:/images/card/06-00.jpg';
import b6v1 from 'url:/images/card/06-01.jpg';
import b6v2 from 'url:/images/card/06-02.jpg';
import b6v3 from 'url:/images/card/06-03.jpg';
import p6 from 'url:/images/card/06.png';

import b7v0 from 'url:/images/card/07-00.jpg';
import b7v1 from 'url:/images/card/07-01.jpg';
import b7v2 from 'url:/images/card/07-02.jpg';
import b7v3 from 'url:/images/card/07-03.jpg';
import p7 from 'url:/images/card/07.png';

import b8v0 from 'url:/images/card/08-00.jpg';
import b8v1 from 'url:/images/card/08-01.jpg';
import b8v2 from 'url:/images/card/08-02.jpg';
import b8v3 from 'url:/images/card/08-03.jpg';
import p8 from 'url:/images/card/08.png';

import b9v0 from 'url:/images/card/09-00.jpg';
import b9v1 from 'url:/images/card/09-01.jpg';
import b9v2 from 'url:/images/card/09-02.jpg';
import b9v3 from 'url:/images/card/09-03.jpg';
import p9 from 'url:/images/card/09.png';

import badge_paw from 'url:/images/badges-paw.png';
import badge_master from 'url:/images/badges-master.png';
import badge_cake from 'url:/images/badges-cake.png';
import badge_olymp from 'url:/images/badges-olymp.png';
import badge_sloth from 'url:/images/badges-sloth.png';
import badge_flash from 'url:/images/badges-flash.png';
import badge_jelly from 'url:/images/badges-jelly.png';
import badge_marty from 'url:/images/badges-marty.png';
import badge_default from 'url:/images/badges-default.png';

//Detect style of preview
const countPlayersDesign = function (player) {
  const playerTotal = player.total;
  const playerRating = player.rating;
  let playersDesign = {
    backgoundImg: '',
    plateImg: '',
    accentColor: '',
    numberColor: '',
    nameColor: '',
  };
  if (playerTotal >= 36 && playerTotal <= 39) {
    playersDesign.accentColor = PREVIEW_ACCENT_COLORS.score30;
    playersDesign.numberColor = PREVIEW_NUMBER_COLORS.scoreBefore90;
    playersDesign.nameColor = PREVIEW_NAME_COLORS.scoreBefore90;
    playersDesign.plateImg = p3;
    switch (playerRating) {
      case 0:
        playersDesign.backgoundImg = b3v0;
        break;
      case 1:
        playersDesign.backgoundImg = b3v1;
        break;
      case 2:
        playersDesign.backgoundImg = b3v2;
        break;
      case 3:
        playersDesign.backgoundImg = b3v3;
        break;
    }
  } else if (playerTotal >= 40 && playerTotal <= 49) {
    playersDesign.accentColor = PREVIEW_ACCENT_COLORS.score40;
    playersDesign.numberColor = PREVIEW_NUMBER_COLORS.scoreBefore90;
    playersDesign.nameColor = PREVIEW_NAME_COLORS.scoreBefore90;
    playersDesign.plateImg = p4;
    switch (playerRating) {
      case 0:
        playersDesign.backgoundImg = b4v0;
        break;
      case 1:
        playersDesign.backgoundImg = b4v1;
        break;
      case 2:
        playersDesign.backgoundImg = b4v2;
        break;
      case 3:
        playersDesign.backgoundImg = b4v3;
        break;
    }
  } else if (playerTotal >= 50 && playerTotal <= 59) {
    playersDesign.accentColor = PREVIEW_ACCENT_COLORS.score50;
    playersDesign.numberColor = PREVIEW_NUMBER_COLORS.scoreBefore90;
    playersDesign.nameColor = PREVIEW_NAME_COLORS.scoreBefore90;
    playersDesign.plateImg = p5;
    switch (playerRating) {
      case 0:
        playersDesign.backgoundImg = b5v0;
        break;
      case 1:
        playersDesign.backgoundImg = b5v1;
        break;
      case 2:
        playersDesign.backgoundImg = b5v2;
        break;
      case 3:
        playersDesign.backgoundImg = b5v3;
        break;
    }
  } else if (playerTotal >= 60 && playerTotal <= 69) {
    playersDesign.accentColor = PREVIEW_ACCENT_COLORS.score60;
    playersDesign.numberColor = PREVIEW_NUMBER_COLORS.scoreBefore90;
    playersDesign.nameColor = PREVIEW_NAME_COLORS.scoreBefore90;
    playersDesign.plateImg = p6;
    switch (playerRating) {
      case 0:
        playersDesign.backgoundImg = b6v0;
        break;
      case 1:
        playersDesign.backgoundImg = b6v1;
        break;
      case 2:
        playersDesign.backgoundImg = b6v2;
        break;
      case 3:
        playersDesign.backgoundImg = b6v3;
        break;
    }
  } else if (playerTotal >= 70 && playerTotal <= 79) {
    playersDesign.accentColor = PREVIEW_ACCENT_COLORS.score70;
    playersDesign.numberColor = PREVIEW_NUMBER_COLORS.scoreBefore90;
    playersDesign.nameColor = PREVIEW_NAME_COLORS.scoreBefore90;
    playersDesign.plateImg = p7;
    switch (playerRating) {
      case 0:
        playersDesign.backgoundImg = b7v0;
        break;
      case 1:
        playersDesign.backgoundImg = b7v1;
        break;
      case 2:
        playersDesign.backgoundImg = b7v2;
        break;
      case 3:
        playersDesign.backgoundImg = b7v3;
        break;
    }
  } else if (playerTotal >= 80 && playerTotal <= 89) {
    playersDesign.accentColor = PREVIEW_ACCENT_COLORS.score80;
    playersDesign.numberColor = PREVIEW_NUMBER_COLORS.scoreBefore90;
    playersDesign.nameColor = PREVIEW_NAME_COLORS.scoreBefore90;
    playersDesign.plateImg = p8;
    switch (playerRating) {
      case 0:
        playersDesign.backgoundImg = b8v0;
        break;
      case 1:
        playersDesign.backgoundImg = b8v1;
        break;
      case 2:
        playersDesign.backgoundImg = b8v2;
        break;
      case 3:
        playersDesign.backgoundImg = b8v3;
        break;
    }
  } else if (playerTotal >= 90 && playerTotal <= 99) {
    playersDesign.accentColor = PREVIEW_ACCENT_COLORS.score90;
    playersDesign.numberColor = PREVIEW_NUMBER_COLORS.scoreAfter90;
    playersDesign.nameColor = PREVIEW_NAME_COLORS.scoreAfter90;
    playersDesign.plateImg = p9;
    switch (playerRating) {
      case 0:
        playersDesign.backgoundImg = b9v0;
        break;
      case 1:
        playersDesign.backgoundImg = b9v1;
        break;
      case 2:
        playersDesign.backgoundImg = b9v2;
        break;
      case 3:
        playersDesign.backgoundImg = b9v3;
        break;
    }
  }
  return playersDesign;
};

//BADGES DETECTING
const badgeDetect = function (badges) {
  const badgesLinks = [];
  for (let i = 0; i < 4; i++) {
    switch (badges[i]) {
      case 'paw':
        badgesLinks.push(badge_paw);
        break;
      case 'master':
        badgesLinks.push(badge_master);
        break;
      case 'cake':
        badgesLinks.push(badge_cake);
        break;
      case 'olympia':
        badgesLinks.push(badge_olymp);
        break;
      case 'sloth':
        badgesLinks.push(badge_sloth);
        break;
      case 'flash':
        badgesLinks.push(badge_flash);
        break;
      case 'jelly':
        badgesLinks.push(badge_jelly);
        break;
      case 'marty':
        badgesLinks.push(badge_marty);
        break;
      default:
        badgesLinks.push(badge_default);
    }
  }
  return badgesLinks;
};

//GENERATING CARD
export const generateCardPreview = function (player) {
  if (!player) return generateEmtpyCard();
  const { backgoundImg, plateImg, accentColor, numberColor, nameColor } = countPlayersDesign(player);
  const [url1, url2, url3, url4] = badgeDetect(player.badges);
  return `<svg class="svg-mask w-inline-block" style="overflow: visible" viewBox="0 0 200 260" fill="none" xmlns="http://www.w3.org/2000/svg">
  <a href="/player${DOTHTML}#${player.id}">
      <g filter="url(#filter01)">
          <path d="${MASK_PATH}" fill=${PREVIEW_MAIN_COLOR}/>
      </g>
      <path d="${MASK_PATH}" fill="#1A1A1A"/>
      <image height="100%" xlink:href="${backgoundImg}" x="0" y="0" mask="url(#mask)"/>
      <path d="${MASK_PATH}" stroke="${PREVIEW_MAIN_COLOR}" stroke-width="1"/>
      <image x="28" y="14" height="150px" width="150" xlink:href="${player.faceUrl}" />
      <path opacity="0.7" d="M0 195V259.5L76 260L83.5 253H161L199.5 227.5V164H6.5V189L0 195Z" fill="#000"/>
      <path d="M6.5 164H199" stroke="${accentColor}"/>
      <path opacity="0.8" d="M65 0H135L130 5V25L125 30H75L70 25V5L65 0Z" fill="${PREVIEW_MAIN_COLOR}"/>
      <text x="50%" y="22" fill="${numberColor}" font-size="25px"text-anchor="middle">${player.id
    .toString()
    .slice(0, 4)
    .padStart(4, '0')}</text>
      <rect opacity="0.9" x="10" y="10" width="44" height="44" fill="#272727"/>
      <path d="M10 14.1026V10H14" stroke="${PREVIEW_MAIN_COLOR}"/>
      <path d="M54 49.8974V54H50" stroke="${PREVIEW_MAIN_COLOR}"/>
      <text x="32" y="40" fill="#ffff" font-size="30px"text-anchor="middle">${player.total}</text>
      <image width="20px" height="20px" xlink:href="${url1}" x="145" y="10"/>
      <image width="20px" height="20px" xlink:href="${url2}" x="169" y="10"/>
      <image width="20px" height="20px" xlink:href="${url3}" x="145" y="34"/>
      <image width="20px" height="20px" xlink:href="${url4}" x="169" y="34"/>
      <image x="9" y="167" height="84px" width="181" xlink:href="${plateImg}" />
      <text x="53%" y="198" fill="${nameColor}" font-size="20px"text-anchor="middle" font-family="Digital" style="text-shadow: 0 0 5px ${nameColor};">${
    player.firstName
  } ${player.lastName}</text>
      <text x="33px" y="219" fill="${accentColor}" font-size="19px" text-anchor="right" font-family="Crptl Dgts" style="text-shadow: 0 0 5px ${accentColor};">${
    player.skill
  }</text>
      <text x="112px" y="219" fill="${accentColor}" font-size="19px"text-anchor="right" font-family="Crptl Dgts" style="text-shadow: 0 0 5px ${accentColor};">${
    player.physical
  }</text>
      <text x="33px" y="236" fill="${accentColor}" font-size="19px"text-anchor="right" font-family="Crptl Dgts" style="text-shadow: 0 0 5px ${accentColor};">${
    player.pace
  }</text>
      <text x="112px" y="236" fill="${accentColor}" font-size="21px"text-anchor="right" font-family="Digital" style="text-shadow: 0 0 5px ${accentColor};">${
    player.position
  }</text>
      <defs>
          <filter id="filter01" x="0" y="0"filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_0_1"/>
          </filter>
          <mask id="mask">
              <path id="card" d="${MASK_PATH}" fill="#fff"/>
          </mask>
      </defs>
  </a>
</svg>`;
};

//-------PLACEHOLDERS---------
const generateCardPlaceholder = function () {
  return `<svg class="svg-mask w-inline-block" viewBox="0 0 200 260">
    <defs>  
        <linearGradient id="moving-gradient" x1="0%" y1="50%" x2="100%" y2="50%"> 
            <stop offset="0%" stop-color="#1b1b1b">
                <animate attributeName="stop-color" values="#1b1b1b; #303030; #1b1b1b" dur="5s" repeatCount="indefinite">
                </animate>
            </stop>
            <stop offset="100%" stop-color="#303030">
                <animate attributeName="stop-color" values="#303030; #1b1b1b; #303030" dur="5s" repeatCount="indefinite"></animate>
            </stop>
        </linearGradient> 
    </defs>
    <path d="${MASK_PATH}" stroke-width="1"/>
    <path fill="url('#moving-gradient')" d="${MASK_PATH}"></path>
  </svg>`;
};

export const renderPlaceholders = function (parentEl, count) {
  parentEl.textContent = '';
  for (let i = count; i > 0; i--) {
    parentEl.insertAdjacentHTML('beforeend', generateCardPlaceholder());
  }
};

export const generateEmtpyCard = function () {
  return `<svg class="svg-mask w-inline-block" style="overflow: visible" viewBox="0 0 200 260" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="${MASK_PATH}" stroke="${PREVIEW_MAIN_COLOR}" stroke-width="1"/>
  </svg>
  `;
};

export const generateCreatingCard = function () {
  return `<svg class="svg-mask w-inline-block" style="overflow: visible" viewBox="0 0 200 260" fill="none" xmlns="http://www.w3.org/2000/svg">
  <defs>  
    <linearGradient id="moving-gradient" x1="0%" y1="50%" x2="100%" y2="50%"> 
      <stop offset="0%" stop-color="#1b1b1b">
        <animate attributeName="stop-color" values="#1b1b1b; #303030; #1b1b1b" dur="5s" repeatCount="indefinite">
        </animate>
      </stop>
      <stop offset="100%" stop-color="#303030">
        <animate attributeName="stop-color" values="#303030; #1b1b1b; #303030" dur="5s" repeatCount="indefinite"></animate>
      </stop>
    </linearGradient> 
  </defs>
  <path fill="url('#moving-gradient')" d="${MASK_PATH}"></path>
  <path d="${MASK_PATH}" stroke="${PREVIEW_MAIN_COLOR}" stroke-width="1"/>
  <text x="50%" y="35%" fill="#5B5B5B" font-size="25px" text-anchor="middle">
  <tspan x="50%" dy="1.2em">This crptl-player is</tspan>
  <tspan x="50%" dy="1.2em">creating right now</tspan>
  </text>
  </svg>
  `;
};

export const renderCardPreview = function (player) {
  if (player.faceUrlOnBlockchain === null || player.status === 'pending_seed' || player.status === 'pending_image' || player.status === 'pending_upload') {
    return generateCreatingCard();
  } else if (player === null) {
    return generateEmtpyCard();
  } else {
    return generateCardPreview(player);
  }
};
