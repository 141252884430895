import Web3 from 'web3';

import { NUMBER_OF_LAST_CREATED, SERVER_URL, PROVIDER, CONTRACT_ADDRESS, CONTRACT_ABI } from './config.js';
import { showErrorPopup } from './utils.js';
import { renderPlaceholders, renderCardPreview } from './cardPreviewGenerator.js';
import { crptlGreetings } from './utils.js';
import { reformatPlayerObjectFromServer } from './utils.js';


//CONNECT TO BLOCKCHAIN
const web3 = new Web3(PROVIDER);
const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);

//GET ELEMENTS
const alreadyCreatedEl = document.querySelector('.section-created__counter');
const currentPriceEl = document.querySelector('.section-last__counter-number');
const cursors = document.querySelectorAll('.cursor-span');
const previewPlayersGrid = document.querySelector('.section-recently__cards-grid');

//CURSOR FLICKERING
const cursorFlickering = setInterval(function () {
  cursors.forEach((el) => el.classList.toggle('cursor-flicker'));
}, 500);

//FETCHING CREATED COUNT AND RENDERING PRICE
let intervalCreatedLoading;
const alreadyCreatedCountPlaceholder = function () {
  let i = 0;
  intervalCreatedLoading = setInterval(() => {
    const loadingPlaceholder = [0, 0, 0, 0];
    loadingPlaceholder[i] = '-';
    alreadyCreatedEl.textContent = `${loadingPlaceholder.join('')}`;
    const newArr = loadingPlaceholder.slice();
    newArr.splice(1, 0, `.`);
    currentPriceEl.textContent = `${newArr.join('')}`;
    i++;
    if (i === 4) {
      i = 0;
    }
  }, 500);
};

const getAlreadyCreatedCount = async function () {
  try {
    alreadyCreatedCountPlaceholder();
    const [priceInWei, alreadyCreated] = await Promise.all([
      contract.methods.currentPrice().call(),
      contract.methods.totalSupply().call(),
    ]);
    const priceInEth = web3.utils.fromWei(priceInWei);
    clearInterval(intervalCreatedLoading);
    alreadyCreatedEl.textContent = alreadyCreated.padStart(4, '0');
    currentPriceEl.textContent = priceInEth;
  } catch (err) {
    clearInterval(intervalCreatedLoading);
    alreadyCreatedEl.textContent = '----';
    currentPriceEl.textContent = '-.---';
    showErrorPopup(`I couldn't connect to the blockchain to update the current price and count of crptl-players`);
  }
};

//RENDERING PLAYERS PREVIEWS
const renderLastCreatedPreviews = async function () {
  try {
    const res = await fetch(`${SERVER_URL}/players/?page=1&per_page=8&reversed=true`);
    const data = await res.json();
    const lastCreatedPlayers = data.slice(0, NUMBER_OF_LAST_CREATED);
    previewPlayersGrid.textContent = '';
    lastCreatedPlayers.forEach((playerFromServer) => {
      const player = reformatPlayerObjectFromServer(playerFromServer);
      previewPlayersGrid.insertAdjacentHTML('beforeend', renderCardPreview(player));
    });
  } catch (err) {
    previewPlayersGrid.textContent = '';
    previewPlayersGrid.insertAdjacentHTML('beforeend', errorMarkup);
  }
};

const errorMarkup = `<div class="section-recently__error-message">
<div id="w-node-_4000a605-1fd0-6498-b500-6375592c0399-5119844a">Sorry, I couldn't load the list of recently created players</div>
</div>`;

const init = function () {
  getAlreadyCreatedCount();
  renderPlaceholders(previewPlayersGrid, NUMBER_OF_LAST_CREATED);
  renderLastCreatedPreviews();
};

init();
crptlGreetings(`Knock, knock. Who's there? Debugger. Debugger who? Debugger not to love these hidden messages.`);
